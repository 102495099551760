#card-row {
    padding: 10px;
}


.row {
    width: 100% !important;
    margin: 0px !important;
}

::placeholder { 
    color:  rgb(183, 178, 178);
    opacity: 1; 
  }

.main-container {
    background: rgba(187, 164, 164, 0.125);

        .card {
            height: 271px;
            padding: 10px 10px 0px 10px !important;
            border-radius: 5px !important;
            box-shadow: 0 10px 6px -6px #777;
            margin-bottom: 18px;
            .mrp-value{
                color: lightslategrey;
                font-weight: 500;
                text-align: center;
            }

            .offer-label{
                margin-top: 10px;
                font-size: 10px;
                color: rgb(183, 178, 178);
                text-align: center;
            }

                .offer{
                    width: 100%;
                    border-radius: 7px;
                    text-align: center;
                    background: #11E95B;
                    color: white;
                    padding: 9px;
                .offer-value{
                    width: 100%;
                }
            }

            .offer-expiry{
                font-variant: small-caps;
                font-size: 13px;
                margin-top: 18px;
                border: 1px solid red;
                background: red;
                color: white;
                text-align: center;
                padding-bottom: 2px;
            }


            .card-title {
                font-weight: 600;
                font-size: 21px;
            }

            .body-cards {
                display: flex;
                justify-content: space-between;

                .card-rate {
                    display: flex;
                    flex-direction: column
                }

                .card-date {
                    display: flex;
                    align-items: flex-end;
                    font-size: 11px;

                }
            }
        }
    

    .quotation {
        text-align: center;
        padding: 20px 0px;        
    }

    .search-container {
        .search {
            display: flex;
            align-content: center;
            justify-content: center;

            span {
                font-size: 20px;
                border: 1px solid;
                border-right: none;
                padding: 5px;
            }
        }
    }


    .header-container {
        width: 100%;
        overflow: hidden;

        #header-row {
            position: relative;
            padding: 8px;
            z-index: 10;
            overflow: hidden;
            background: #424894;
            box-shadow: 5px 0px 4px 7px #777;
            color: white;
            .header-number {
                margin-left: 5px;
                font-size: 12px;
            }
            .header-address {
                margin-left: 5px;
                font-size: 12px;
                margin-right: 15px
            }
        }
    }

}

// .call{
//     padding: 15px;
//     background: #3f9bdc;
//     text-align: center;
//     color: white;
//     font-size: 20px;
// }

#header-info{
    display: flex;
    flex-direction: column;
}


@media (max-width: 991px) {
    .header-info {
        display: flex;
        flex-direction: column;
    }
}
